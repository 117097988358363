import PriceRange from "../Partials/PriceRange";
import PropertyType from "../Partials/PropertyType";

export default function HeroSection({ setQueryParameters, queryParameters }) {
    const page = "Index";

    return (
        <div className="row pt-0 pt-md-2 pt-lg-0">
            <div className="col-xl-7 col-lg-6 col-md-5 order-md-2 mb-4 mb-lg-5">
                <img src="/home.jpg" alt="" className="rounded-3" />
            </div>
            <div className="col-xl-5 col-lg-6 col-md-7 order-md-1 pt-xl-5 pe-lg-0 mb-3 text-md-start text-center custom-pr">
                <h1 className="mt-lg-5 mb-md-4 mb-3 pt-md-4 pb-lg-2">
                    Transparent, Direct, and Trustworthy
                </h1>
                <p className="position-relative lead">
                    Welcome to Kajiado Acres – Where Land Transactions Are Transparent, Direct, and Trustworthy.
                    Discover a New Era of Ethical Real Estate Deals in the Heart of Kenya. Explore Land
                    Opportunities with Confidence.
                </p>
            </div>
            <div className="col-xl-12 col-lg-10 order-3 mt-lg-n5">
                <form className="form-group d-block">
                    <div className="row g-0 ms-sm-n2">
                        <div className="col-md-6 d-sm-flex align-items-center">
                            {/* <Locations page={page} /> */}
                            {/* <hr className="d-sm-none my-2" /> */}
                            <PropertyType
                                page={page}
                                setQueryParameters={setQueryParameters}
                                queryParameters={queryParameters}
                            />
                        </div>
                        <hr className="d-md-none mt-2" />
                        <div className="col-md-6 d-sm-flex align-items-center pt-4 pt-md-0">
                            <div className="d-flex align-items-center w-100 pt-2 pb-4 py-sm-0 ps-2 ps-sm-3"><i
                                className="fi-cash fs-lg text-muted me-2"></i><span className="text-muted">Price</span>
                                <PriceRange
                                    page={page}
                                    queryParameters={queryParameters}
                                    setQueryParameters={setQueryParameters}
                                />
                            </div>
                            <button className="btn btn-icon btn-primary px-3 w-100 w-sm-auto flex-shrink-0"
                                type="button"><i className="fi-search"></i><span
                                    className="d-sm-none d-inline-block ms-2">Search</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};