import PageWrapper from "../components/PageWrapper";
import HeroSection from "../components/Sections/HeroSection";
import RecentListingSection from "../components/Sections/RecentListingSection";

export default function IndexPage({ setQueryParameters, queryParameters }) {
    const page = "Index";
    return (
        <PageWrapper page={page}>
            <HeroSection
                setQueryParameters={setQueryParameters}
                queryParameters={queryParameters}
            />
            <RecentListingSection
                queryParameters={queryParameters}
            />
        </PageWrapper>
    )
}