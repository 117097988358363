import { useState, useEffect, useRef } from "react";
import "nouislider/dist/nouislider.css";

import { useMetaData } from "../Contexts/MetadataContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function LandArea({ queryParameters, setQueryParameters }) {
    const { maxSize, minSize } = useMetaData();

    // Initialize minValue and maxValue with minSize and maxSize, providing defaults if they are null
    const [minValue, setMinValue] = useState((minSize || 0).toString());
    const [maxValue, setMaxValue] = useState((maxSize || 0).toString());

    const minSizeInputRef = useRef(null);
    const maxSizeInputRef = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Handle initial values from useMetaData
        if (minSize !== null && maxSize !== null) {
            setMinValue(minSize.toString());
            setMaxValue(maxSize.toString());
        }
    }, [minSize, maxSize]);

    const handleMinValueChange = (event) => {
        const value = event.target.value;
        setMinValue(value);

        const maxSize = parseFloat(maxSizeInputRef.current.value);

        handleSizeRangeChange(parseFloat(value), maxSize);
    };

    const handleMaxValueChange = (event) => {
        const value = event.target.value;
        setMaxValue(value);

        const minSize = parseFloat(minSizeInputRef.current.value);

        handleSizeRangeChange(minSize, parseFloat(value));
    };

    const handleSizeRangeChange = (newMinValue, newMaxValue) => {
        const minSize = newMinValue;
        const maxSize = newMaxValue;

        // update the URL with the selected property type
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('minSize', newMinValue);
        searchParams.set('maxSize', newMaxValue);
        navigate(location.pathname + '?' + searchParams.toString());

        // update the queryParameters state
        setQueryParameters({ ...queryParameters, minSize, maxSize });
    };

    return (
        <div className="pb-4 mb-2">
            <h3 className="h6">Land Area</h3>
            <div className="d-flex align-items-center">
                <div className="w-50 pe-2">
                    <div className="input-group">
                        <input
                            ref={minSizeInputRef}
                            className="form-control"
                            type="number"
                            step="0.01"
                            value={minValue}
                            onChange={handleMinValueChange}
                        />
                    </div>
                </div>
                <div className="text-muted">&mdash;</div>
                <div className="w-50 ps-2">
                    <div className="input-group">
                        <input
                            ref={maxSizeInputRef}
                            className="form-control"
                            type="number"
                            value={maxValue}
                            onChange={handleMaxValueChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
