import { useState } from "react";
import PriceRange from "./PriceRange";
import PropertyType from "./PropertyType";
import Tags from "./Tags";
import LandArea from "./LandArea";
import { useNavigate } from "react-router-dom";

export default function Filters({setQueryParameters, queryParameters}) {

    const navigate = useNavigate();

    const [selectedTags, setSelectedTags] = useState(queryParameters.tags);

    console.log(selectedTags);

    const resetFilters = () => {
        const updatedQueryParameters = {
            propertyType: null,
            minPrice: null,
            maxPrice: null,
            tags: []
        };

        setQueryParameters(updatedQueryParameters);

        setSelectedTags([]);

        navigate('/properties');
    }

    return (
        <div className="offcanvas-lg offcanvas-start" id="filters-sidebar">
            <div className="offcanvas-header d-flex d-lg-none align-items-center">
                <h2 className="h5 mb-0">Filters</h2>
                <button className="btn-close" type="button" data-bs-dismiss="offcanvas"
                    data-bs-target="#filters-sidebar"></button>
            </div>
            <div className="offcanvas-body py-lg-4">
                <div className="py-4">
                    <button className="btn btn-outline-primary" type="button" onClick={resetFilters}>
                        <i className="fi-rotate-right me-2"></i>
                        Reset filters
                    </button>
                </div>
                {/* <Locations /> */}
                <PropertyType
                    setQueryParameters={setQueryParameters}
                    queryParameters={queryParameters}
                />
                <PriceRange
                    setQueryParameters={setQueryParameters}
                    queryParameters={queryParameters}
                />
                <LandArea
                    setQueryParameters={setQueryParameters}
                    queryParameters={queryParameters}
                />
                <Tags
                    setQueryParameters={setQueryParameters}
                    queryParameters={queryParameters}
                    setSelectedTags={setSelectedTags}
                    selectedTags={selectedTags}
                />
            </div>
        </div>
    );
}