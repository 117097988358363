import ImageSlider from "./Partials/ImageSlider";
import { Link } from "react-router-dom";


export default function Listings({ listings }) {
    const renderPlaceholders = () => {
        const placeholders = [];

        for (let i = 0; i < 6; i++) {
            placeholders.push(
                <div key={i} className="col-sm-6 col-xl-4">
                    <div className="card shadow card-hover border-0 h-100">
                        <div className="tns-carousel-wrapper card-img-top card-img-hover h-100">
                            <svg className="bd-placeholder-img card-img-top" width="100%" height="100%"
                                xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder"
                                preserveAspectRatio="xMidYMid slice" focusable="false">
                                <title>Placeholder</title>
                                <rect width="100%" height="100%" fill="#868e96"></rect>
                            </svg>
                        </div>
                        <div className="card-body position-relative pb-3">
                            <p className="placeholder-glow">
                                <span className="placeholder col-6"></span>
                                <span className="placeholder w-75"></span>
                                <span className="placeholder"></span>
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
        return placeholders;
    };

    return (
        <>
            {listings === undefined || listings === null ? (
                renderPlaceholders()
            ) : (
                <>
                    {listings.length === 0 ? (
                        <p>There are no available listings</p>
                    ) : (
                        listings.map((listing) => {
                            const details = listing.details ? JSON.parse(listing.details) : {};
                            const images = listing.images ? JSON.parse(listing.images).slice(0, 5) : [];

                            return (
                                <div key={listing.id} className="col-sm-6 col-xl-4">
                                    <div className="card shadow card-hover border-0 h-100">
                                        <ImageSlider images={images} />
                                        <div className="card-body position-relative pb-3">
                                            <h3 className="h6 mb-2 fs-base">
                                                <Link to={`/properties/${listing.id}`} className="nav-link stretched-link">
                                                    {listing.title}
                                                </Link>
                                            </h3>
                                            <p className="mb-2 fs-sm text-muted">
                                                {details.Location}
                                            </p>
                                            <div className="fw-bold">
                                                <i className="fi-cash mt-n1 me-2 lead align-middle opacity-70"></i>
                                                Ksh. {details.Price}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </>
            )}
        </>
    );

}