import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import './assets/css/theme.min.css';
import './assets/css/custom.css';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import smoothscroll from 'smoothscroll-polyfill';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { MetadataProvider } from './components/Contexts/MetadataContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

smoothscroll.polyfill();

root.render(
  <React.StrictMode>
    <MetadataProvider>
      <App />
    </MetadataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();