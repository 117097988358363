import { useState, useEffect, useRef } from "react";
import RangeSlider from "./RangeSlider";
import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";
import wNumb from "wnumb";

import { useMetaData } from "../Contexts/MetadataContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function PriceRange({ page, queryParameters, setQueryParameters }) {
    const { maxPrice, minPrice } = useMetaData();

    // Initialize minValue and maxValue with minPrice and maxPrice from context
    const [minValue, setMinValue] = useState(minPrice ? minPrice.toLocaleString() : 0);
    const [maxValue, setMaxValue] = useState(maxPrice ? maxPrice.toLocaleString() : 0);

    const sliderRef = useRef(null);
    const minPriceInputRef = useRef(null);
    const maxPriceInputRef = useRef(null);

    const connect = true;

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Handle initial values from useMetaData
        if (minPrice !== null && maxPrice !== null) {
            setMinValue(minPrice.toLocaleString());
            setMaxValue(maxPrice.toLocaleString());
        }
    }, [minPrice, maxPrice]);

    const handleMinValueChange = (event) => {
        const value = event.target.value.replace(/,/g, ""); // Remove commas
        setMinValue(value);

        const maxPrice = maxPriceInputRef.current.value.replace(/,/g, "");

        sliderRef.current.noUiSlider.set([parseFloat(value), parseFloat(maxPrice)]);
        handlePriceRangeChange(parseFloat(value), parseFloat(maxPrice));
    };

    const handleMaxValueChange = (event) => {
        const value = event.target.value.replace(/,/g, ""); // Remove commas
        setMaxValue(value);

        const minPrice = minPriceInputRef.current.value.replace(/,/g, "");

        sliderRef.current.noUiSlider.set([parseFloat(minPrice), parseFloat(value)]);
        handlePriceRangeChange(parseFloat(minPrice), parseFloat(value));
    };

    const handlePriceRangeChange = (newMinValue, newMaxValue) => {

        setMinValue(newMinValue.toLocaleString());
        setMaxValue(newMaxValue.toLocaleString());

        const minPrice = newMinValue;
        const maxPrice = newMaxValue;

        // update the URL with the selected property type
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('minPrice', newMinValue);
        searchParams.set('maxPrice', newMaxValue);
        navigate(location.pathname + '?' + searchParams.toString());

        // update the queryParameters state
        setQueryParameters({ ...queryParameters, minPrice, maxPrice });
    }

    useEffect(() => {

        const slider = sliderRef.current;

        // Update the input values when minPrice or maxPrice changes
        if (slider) {
            const numericMinPrice = parseFloat(minPrice);
            const numericMaxPrice = parseFloat(maxPrice);

            let startValues;
            let tooltips = [wNumb({ thousand: ",", decimals: 0, prefix: "Ksh." })]; // Initialize tooltips with a default formatter

            if (connect === true) {
                // When connect is true, set different initial values for lower and upper handles
                startValues = [numericMinPrice, numericMaxPrice];
                tooltips = [
                    wNumb({ thousand: ",", decimals: 0, prefix: "Ksh." }),
                    wNumb({ thousand: ",", decimals: 0, prefix: "Ksh." }),
                ];
            } else {
                // When connect is "lower", set the initial value for the lower handle
                startValues = [numericMaxPrice];
            }

            noUiSlider.create(slider, {
                start: startValues,
                range: {
                    min: 0,
                    max: numericMaxPrice,
                },
                step: 500, // Set the step value to 500
                connect: connect === true ? true : "lower", // Use 'true' or 'lower' based on the 'connect' prop
                tooltips,
            });

            slider.noUiSlider.on("slide", (values, handle) => {
                const [newMinValue, newMaxValue] = values.map(parseFloat);
                handlePriceRangeChange(newMinValue, newMaxValue);
            });

            slider.noUiSlider.on("update", (values, handle) => {

            });

        }

        // Destroy the slider when the component unmounts to prevent memory leaks.
        return () => {
            if (slider) {
                slider.noUiSlider.destroy();
            }
        };
        // eslint-disable-next-line
    }, [minPrice, maxPrice, connect]);

    useEffect(() => {
        if (queryParameters.minPrice === null && queryParameters.maxPrice === null) {

            // Update the input field values and the slider values with the new values
            setMinValue(minPrice ? minPrice.toLocaleString() : 0);
            setMaxValue(maxPrice ? maxPrice.toLocaleString() : 0);

            // set slider values here
            sliderRef.current.noUiSlider.set([parseFloat(minPrice), parseFloat(maxPrice)]);
        }
    }, [queryParameters, minPrice, maxPrice])

    return (
        <>
            {page === "Index" ? (
                <RangeSlider
                    sliderRef={sliderRef}
                />
            ) : (
                <>
                    <div className="pb-4 mb-2">
                        <h3 className="h6">Price</h3>
                        <RangeSlider
                            sliderRef={sliderRef}
                        />
                        <div className="d-flex align-items-center">
                            <div className="w-50 pe-2">
                                <div className="input-group">
                                    <input
                                        ref={minPriceInputRef}
                                        className="form-control"
                                        type="text"
                                        value={minValue}
                                        onChange={handleMinValueChange}
                                    />
                                </div>
                            </div>
                            <div className="text-muted">&mdash;</div>
                            <div className="w-50 ps-2">
                                <div className="input-group">
                                    <input
                                        ref={maxPriceInputRef}
                                        className="form-control"
                                        type="text"
                                        value={maxValue}
                                        onChange={handleMaxValueChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-none text-muted mt-2">
                            <b>KES:</b>&nbsp;{minValue} - {maxValue}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
