import { Link } from "react-router-dom"
export default function BreadCrumbs({ listing }) {

    return (
        <>
            <nav className="mb-3 pt-md-2" aria-label="Breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    {listing !== null && listing !== undefined ? (
                        <>
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/properties">All Properties</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {listing.title}
                            </li>
                        </>
                    ) : (
                        <li className="breadcrumb-item active" aria-current="page">
                            All Properties
                        </li>
                    )}

                </ol>
            </nav>
            {listing !== null && listing !== undefined ? (
                <>
                    <h1 className="h2 mb-2">{listing.title}</h1>
                    {listing.details && (
                        <p className="mb-2 pb-1 fs-lg">{JSON.parse(listing.details).Location}</p>
                    )}
                </>
            ) : (
                <div className="d-sm-flex align-items-center justify-content-between pb-3 pb-sm-4">
                    <h1 className="h2 mb-sm-0">Showing&nbsp;<span>All properties</span></h1>
                </div>
            )}
        </>
    )
}