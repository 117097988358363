import { Link } from "react-router-dom";
import { useListings } from "../Hooks/useListings";
import Listings from "../Listings";

export default function RecentListingSection({ queryParameters }) {
    const listingType = "Recent listings";

    // Use the custom hook to fetch listings data
    const { listings } = useListings(listingType, queryParameters);

    const queryParams = { ...queryParameters };
    const queryParamsArray = [];

    for (const key in queryParams) {
        if (queryParams[key] !== null) {
            queryParamsArray.push(`${key}=${queryParams[key]}`);
        }
    }

    const baseUrl = "/properties";

    // construct the final url with query parameters if any exist
    const viewAllUrl =
        queryParamsArray.length > 0
            ? `${baseUrl}?${queryParamsArray.join("&")}`
            : baseUrl
    
    return (
        <div className="row g-4 py-4">
            <div className="d-sm-flex align-items-center justify-content-between">
                <h1 className="h2 mb-sm-0">Recent Listings</h1>
                <Link to={viewAllUrl} className="d-inline-block fw-bold text-decoration-none py-1">
                    View All
                </Link>
            </div>
            <Listings listingType={listingType} listings={listings} />
            <div className="d-flex align-items-center justify-content-center py-3">
                <Link to={viewAllUrl} className="d-inline-block fw-bold text-decoration-none py-1">
                    View All
                </Link>
            </div>
        </div>
    );
};