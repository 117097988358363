import Container from "../components/Container";
import useListing from "../components/Hooks/useListing";
import Listing from "../components/Listing";
import PageWrapper from "../components/PageWrapper";
import BreadCrumbs from "../components/Partials/BreadCrumbs";
import ImageGallery from "../components/Partials/ImageGallery";

export default function PropertyPage() {
    const containerClasses = 'container pt-5 mt-5';
    const { listing, isLoading } = useListing(); // Add isLoading from the useListing hook

    // You can use the 'isLoading' state to determine if data is still loading
    if (isLoading) {
        // Render a loading indicator or message
        return;
    }

    return (
        <PageWrapper>
            <Container containerClasses={containerClasses}>
                <BreadCrumbs listing={listing} />
                {listing.images && (
                    <ImageGallery images={JSON.parse(listing.images)} listing={listing} />
                )}
                <Listing listing={listing} />
            </Container>
        </PageWrapper>
    )
}