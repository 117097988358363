import { useEffect, useState } from "react";
import { useMetaData } from "../Contexts/MetadataContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function Tags({ setQueryParameters, queryParameters, setSelectedTags, selectedTags }) {
    const { tags } = useMetaData();
    const [parsedTags, setParsedTags] = useState([]);

    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        // Check if tags are available and not empty
        if (tags && tags.length > 0) {
            try {
                // Parse the tags into an array
                const parsed = JSON.parse(tags);
                setParsedTags(parsed);
            } catch (error) {
                console.error("Error parsing tags:", error);
            }
        }
    }, [tags]); // This useEffect will run whenever the 'tags' value changes

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        if (selectedTags.length > 0) {
            // construct new URL with the selected tags
            searchParams.set('tags', selectedTags.join(','));
        } else {
            // if the array is empty, remove the 'tags' parameter
            searchParams.delete('tags');
        }

        // replace the current url with the new one
        navigate(location.pathname + '?' + searchParams.toString());

        const tags = selectedTags;
        setQueryParameters({ ...queryParameters, tags })
        // eslint-disable-next-line
    }, [selectedTags])

    const handleTagSelection = async (event, tag) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            // add selected tag to the state variable
            setSelectedTags([...selectedTags, tag]);
        } else {
            // remove tag if unchecked
            setSelectedTags(selectedTags.filter(selectedTag => selectedTag !== tag));
        }
    }
    
    return (
        <div className="pb-4 mb-2">
            <h3 className="h6">Tags</h3>
            <div className="overflow-auto" data-simplebar data-simplebar-auto-hide="false"
                style={{ height: 'auto' }}>
                {parsedTags.length > 0 ? (
                    parsedTags.map((tag) => (
                        <div key={tag} className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={tag}
                                onChange={(e) => handleTagSelection(e, tag)}
                                checked={selectedTags.includes(tag)}
                            />
                            <label className="form-check-label fs-sm" htmlFor={tag}>{tag}</label>
                        </div>
                    ))
                ) : (
                    <p>No tags available</p>
                )}
            </div>
        </div>
    );
}