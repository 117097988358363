
export default function SortBy({ offset, listingsCount, count, total }) {

    return (
        <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch my-2">
            <div className="d-none d-flex align-items-center flex-shrink-0">
                <label className="fs-sm me-2 pe-1 text-nowrap" htmlFor="sortby"><i
                    className="fi-arrows-sort text-muted mt-n1 me-2"></i>Sort by:</label>
                <select className="form-select form-select-sm" id="sortby">
                    <option>Newest</option>
                    <option>Popularity</option>
                    <option>Low - High Price</option>
                    <option>High - Low Price</option>
                    <option>High rating</option>
                    <option>Average Rating</option>
                </select>
            </div>
            <hr className="d-none d-sm-block w-100 mx-4" />
            <div className="d-sm-flex align-items-center flex-shrink-0 text-muted">
                <i className="fi-check-circle me-2"></i>
                {listingsCount === undefined || listingsCount === null ? (
                    <span className="fs-sm mt-n1">fetching...</span>
                ) : listingsCount === 0 ? (
                    <span className="fs-sm mt-n1">No items found</span>
                ) : offset + count === total ? (
                    <span className="fs-sm mt-n1">{`${total} items found`}</span>
                ) : (
                    <span className="fs-sm mt-n1">
                        {`${offset + 1} - ${offset + count} of ${total}`}
                    </span>
                )}
            </div>
        </div >
    )
}