import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export default function PropertyType({ page, setQueryParameters, queryParameters }) {
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [loading, setLoading] = useState(true); // track loading status
    const [selectedPropertyType, setSelectedPropertyType] = useState(queryParameters.propertyType || null);
    const [propertyTypeName, setPropertyTypeName] = useState("Property type");

    const navigate = useNavigate();
    const location = useLocation();

    // Initialize selectedPropertyType based on queryParameters
    useEffect(() => {
        setSelectedPropertyType(queryParameters.propertyType || null);
    }, [queryParameters.propertyType]);

    useEffect(() => {
        const fetchPropertyTypes = async () => {
            try {
                const response = await fetch(BASE_API_URL + "/api/propertyTypes");
                if (response.ok) {
                    const results = await response.json();
                    setPropertyTypes(results.data);
                    setLoading(false); // data is loaded
                } else {
                    setPropertyTypes([]);
                    setLoading(false); // data not loading due to error
                }
            } catch (error) {
                console.error("Error fetching property types:", error);
                setPropertyTypes([]);
                setLoading(false);
            }
        };

        fetchPropertyTypes();
    }, []);

    const handlePropertyTypeSelection = async (event, propertyType) => {
        event.preventDefault();

        setSelectedPropertyType(propertyType);

        // update the URL with the selected property type
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('propertyType', propertyType);

        // Set max & min price to null
        const maxPrice = null;
        const minPrice = null;

        // Check if maxPrice and minPrice are null and remove them from the URL if present
        if (maxPrice === null) {
            searchParams.delete('maxPrice');
        }
        if (minPrice === null) {
            searchParams.delete('minPrice');
        }

        navigate(location.pathname + '?' + searchParams.toString());

        // update the queryParameters state
        setQueryParameters({ ...queryParameters, propertyType, minPrice, maxPrice })
    }

    useEffect(() => {
        // update property name when propertyTypes and selectedPropertyType are ready
        if (!loading && selectedPropertyType) {
            const propertyTypeName = propertyTypes.find(propertyType => propertyType.id === selectedPropertyType);
            if (propertyTypeName && propertyTypeName.name) {
                setPropertyTypeName(propertyTypeName.name);
            }
        }
    }, [loading, selectedPropertyType, propertyTypes]);

    return (
        <>
            {page === "Index" ? (
                <div className="dropdown w-100 border-end-md" data-bs-toggle="select">
                    <button className="btn btn-link dropdown-toggle ps-2 ps-sm-3" type="button"
                        data-bs-toggle="dropdown">
                        <i className="fi-list me-2"></i>
                        <span className="dropdown-toggle-label">
                            {loading ? "Property type" : propertyTypeName}
                        </span>
                    </button>
                    <input type="hidden" />
                    <ul className="dropdown-menu">
                        {propertyTypes === undefined || propertyTypes === null ? (
                            <span>Fetching property types...</span>
                        ) : propertyTypes.length === 0 ? (
                            <span>No property types to display...</span>
                        ) : (
                            propertyTypes.map((propertyType) => (
                                <li key={propertyType.id}>
                                    <a className="dropdown-item" href="?" onClick={(e) => handlePropertyTypeSelection(e, propertyType.id)}>
                                        <span className="dropdown-item-label">
                                            {propertyType.name}
                                        </span>
                                    </a>
                                </li>
                            ))
                        )}

                    </ul>
                </div>) : (
                <div className="pb-4 mb-2">
                    <h3 className="h6">Property type</h3>
                    <div className="overflow-auto" data-simplebar data-simplebar-auto-hide="false"
                        style={{ height: 'auto' }}>
                        {propertyTypes === undefined || propertyTypes === null ? (
                            <span>Fetching property types...</span>
                        ) : propertyTypes.length === 0 ? (
                            <span>No property types to display...</span>
                        ) : (
                            propertyTypes.map((propertyType) => (
                                <div key={propertyType.id} className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={propertyType.id}
                                        checked={propertyType.id === selectedPropertyType}
                                        onChange={(e) => handlePropertyTypeSelection(e, propertyType.id)}
                                    />
                                    <label className="form-check-label fs-sm" htmlFor={propertyType.id}>{propertyType.name}</label>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
        </>
    );
}