import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Pagination({ pagination, loadNext }) {
    let thereAreMore = false;
    let totalPages = 0;
    let currentPage = 1;

    if (pagination) {
        const { limit, total, offset } = pagination;
        totalPages = Math.ceil(total / limit);
        currentPage = Math.ceil(offset / limit) + 1;
        thereAreMore = currentPage < totalPages;
    }

    const navigate = useNavigate();

    const generatePageNumbers = (totalPages, currentPage) => {
        const pageNumbers = [];
        const maxPageNumbers = 5;
        const pageNumbersToShow = [];

        if (totalPages <= maxPageNumbers) {
            // If total pages are less than or equal to maxPageNumbers, show all page numbers.
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            // Calculate the start and end of the page numbers to display.
            let start, end;

            if (currentPage <= Math.ceil(maxPageNumbers / 2)) {
                // If current page is near the beginning, show the first maxPageNumbers pages.
                start = 1;
                end = maxPageNumbers;
            } else if (currentPage >= totalPages - Math.floor(maxPageNumbers / 2)) {
                // If current page is near the end, show the last maxPageNumbers pages.
                start = totalPages - maxPageNumbers + 1;
                end = totalPages;
            } else {
                // Show current page with sibling pages on both sides and ellipses.
                start = currentPage - Math.floor(maxPageNumbers / 2);
                end = currentPage + Math.floor(maxPageNumbers / 2);
            }

            // Add ellipses if needed.
            if (start > 1) {
                pageNumbersToShow.push(1);
                if (start > 2) {
                    pageNumbersToShow.push('...');
                }
            }

            // Add page numbers to display.
            for (let i = start; i <= end; i++) {
                pageNumbersToShow.push(i);
            }

            // Add ellipses if needed.
            if (end < totalPages) {
                if (end < totalPages - 1) {
                    pageNumbersToShow.push('...');
                }
                pageNumbersToShow.push(totalPages);
            }
        }

        return pageNumbersToShow;
    };

    const handlePageLinkClick = (pageNumber, event) => {
        if (typeof pageNumber === 'number') {
            const newPage = Math.min(Math.max(1, pageNumber), totalPages);
            const offset = (newPage - 1) * pagination.limit;
            loadNext(offset, newPage);

            // Prevent the default behavior of the anchor link (preventing '#' from being added to the URL)
            event.preventDefault();            
            navigate(`/properties?page=${newPage}`);
        }
    };

    const pageNumbers = generatePageNumbers(totalPages, currentPage);

    return (
        <nav className="border-top pb-md-4 pt-4 mt-2" aria-label="Pagination">
            <ul className="pagination mb-1">
                {pageNumbers.map((pageNumber, index) => (
                    <li
                        key={index}
                        className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                        aria-current={pageNumber === currentPage ? 'page' : undefined}
                    >
                        {pageNumber === '...' ? (
                            <span className="page-link">...</span>
                        ) : (
                            <a
                                className="page-link"
                                href={`/properties?page=${pageNumber}`}
                                onClick={(event) => handlePageLinkClick(pageNumber, event)}
                            >
                                {pageNumber}
                            </a>
                        )}
                    </li>
                ))}
                {thereAreMore && (
                    <li className="page-item">
                        <a
                            className="page-link"
                            href={`/properties?page=${currentPage + 1}`}
                            onClick={(event) => handlePageLinkClick(currentPage + 1, event)}
                            aria-label="Next"
                        >
                            <i className="fi-chevron-right"></i>
                        </a>
                    </li>
                )}
            </ul>
        </nav>
    );
}
