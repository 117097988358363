import Markdown from 'react-markdown'
import Aside from './Partials/Aside'

export default function Listing({ listing }) {
    const details = JSON.parse(listing.details)
    const asideClassList = ["col-lg-4 col-md-5 ms-lg-auto pb-1"]
    return (
        <section className="container mb-5 pb-1">
            <div className="row">
                <div className="col-md-7 mb-md-0 mb-4">
                    <h2 className="h3 mb-4 pb-4 border-bottom">
                        Ksh.&nbsp;{JSON.parse(listing.details).Price}
                    </h2>
                    <div className="mb-4 pb-md-3">
                        <h3 className="h4">Overview</h3>
                        <Markdown>{listing.description}</Markdown>
                    </div>
                    <div className="mb-4 pb-md-3">
                        <h3 className="h4">Property Details</h3>
                        <ul className="list-unstyled mb-0">
                            {Object.entries(details).map(([key, value], index) => (
                                <li key={index}>
                                    <b>{key}: </b>
                                    {value}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <Aside classList={asideClassList} />
            </div>
        </section>
    )
}