import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import IndexPage from './Pages/IndexPage';
import PropertiesPage from './Pages/PropertiesPage';
import PropertyPage from './Pages/PropertyPage';
import ApiProvider from './components/Contexts/ApiProvider';
import { useEffect, useState } from 'react';

export default function App() {
  const queryParams = new URLSearchParams(window.location.search);

  const initialQueryParams = {
    propertyType: null,
    minPrice: null,
    maxPrice: null,
    tags: [],
  }

  const [queryParameters, setQueryParameters] = useState(initialQueryParams)

  // update queryParameters when the URL changes
  useEffect(() => {
    const updatedQueryParameters = { ...initialQueryParams };
    
    // iterate over the possible query parameters and set them if they exist in the URL
    for (const param of Object.keys(initialQueryParams)) {
      const value = queryParams.get(param);
      if (value !== null && value !== '') {
        updatedQueryParameters[param] = value;
      }
    }

    setQueryParameters(updatedQueryParameters);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BrowserRouter>
        <ApiProvider>
          <Routes>
            <Route
              path="/"
              element={
                <IndexPage
                  setQueryParameters={setQueryParameters}
                  queryParameters={queryParameters}
                />}
            />
            <Route
              path="/properties"
              element={
                <PropertiesPage
                  queryParameters={queryParameters}
                  setQueryParameters={setQueryParameters}
                />}
            />
            <Route path="/properties/:id" element={<PropertyPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ApiProvider>
      </BrowserRouter>
    </>
  );
}