export default function Navbar() {
    return (
        <>
            <a className="navbar-brand me-3 me-xl-4" href="/">
                <img className="d-block" src="/kajiado_acres.svg" width="116" alt="Kajiado Acres" />
                <span className="brand-text">Kajiado Acres</span>
            </a>
            <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <a className="d-none btn btn-primary btn-sm ms-2 order-lg-3" href="add_property.html">
                <i className="fi-plus me-2"></i>
                Add&nbsp;
                <span className='d-none d-sm-inline'>property</span>
            </a>
            <div className="collapse navbar-collapse order-lg-2" id="navbarNav">
                <ul className="navbar-nav navbar-nav-scroll" style={{ maxHeight: '35rem' }}>
                    <li className="nav-item active">
                        <a className="nav-link" href="?" role="button">Home</a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="?" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">Properties
                        </a>
                        <ul className="dropdown-menu">
                            <li>
                                <a className="dropdown-item" href="real-estate-catalog-rent.html">
                                    Farm land
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="real-estate-single-v2.html">
                                    Mixed use land
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="real-estate-single-v1.html">
                                    Commercial land
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="real-estate-catalog-sale.html">
                                    Residential land
                                </a>
                            </li>

                            <li>
                                <a className="dropdown-item" href="real-estate-single-v2.html">
                                    Rocky/Quarry land
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="real-estate-single-v2.html">
                                    Houses & Apartments
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="?" role="button">Services</a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" href="?" role="button">
                            About Us
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};