export default function InputField({ classList, type, placeholder, required, name, inputRef, error }) {

    return (
        <>
            <span style={{ color: 'red' }}>
                {error}
            </span>
            <input name={name} className={classList} type={type} placeholder={placeholder} required={required} ref={inputRef} />
        </>
    );
}