import { useState, useEffect, useCallback } from "react";
import { useApi } from "../Contexts/ApiProvider";
import { useMetaData } from "../Contexts/MetadataContext";
import { useLocation } from "react-router-dom";

export function useListings(listingType, queryParameters) {
    const location = useLocation();

    // Use URLSearchParams to parse the query parameters
    const queryParams = new URLSearchParams(location.search);

    // Retrieve the 'page' query parameter
    const initialPageNumber = queryParams.get('page');

    const [pageNumber, setPageNumber] = useState(initialPageNumber);

    const [listings, setListings] = useState([]);
    const [listingsCount, setListingsCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);

    const api = useApi();
    const {
        setMaxPrice,
        setMinPrice,
        setMaxSize,
        setMinSize,
        setTags,
        setPagination,
    } = useMetaData();

    const loadListings = useCallback(async (offset, page) => {
        const numericOffset = typeof offset === 'number' ? offset : offset.offset;

        // create an array of key-value pairs from queryParameter
        const params = { ...queryParameters };
        const paramsArray = [];

        for (const key in params) {
            if (key === "tags") {
                if (params[key].length > 0) {
                    paramsArray.push(`${key}=${params[key]}`)
                }
            } else {
                if (params[key] !== null && params[key] !== undefined) {
                    paramsArray.push(`${key}=${params[key]}`);
                }
            }

        }

        let apiRequest;
        if (listingType === "Recent listings") {
            if (paramsArray.length > 0) {
                apiRequest = `/listings?limit=6&${paramsArray.join("&")}`;
            } else {
                apiRequest = `/listings?limit=6`;
            }
        } else if (listingType === "All listings") {
            if ((page === undefined) || (page === null)) {
                if (paramsArray.length > 0) {
                    apiRequest = `/listings?${paramsArray.join("&")}`;
                } else {
                    apiRequest = `/listings?offset=${numericOffset}`;
                }
            } else {
                // if a page number is provided, set a limit
                const itemsPerPage = 25;
                const offset = (page - 1) * itemsPerPage;
                apiRequest = `/listings?offset=${offset}&limit=${itemsPerPage}`;
            }
        }

        const response = await api.get(apiRequest);


        if (response.ok) {
            const data = response.body.data || [];
            setListings(data);
            setMaxPrice(response.body.metadata['max_price']);
            setMinPrice(response.body.metadata['min_price']);
            setMaxSize(response.body.metadata['max_size']);
            setMinSize(response.body.metadata['min_size']);
            setTags(response.body.metadata["tags"]);
            setListingsCount(response.body.pagination.count);
            setOffset(response.body.pagination.offset);
            setTotal(response.body.pagination.total);
            setPagination(response.body.pagination);
            if (page !== null && page !== undefined) {
                setPageNumber(page); // Set pageNumber when provided
            }

        } else {
            setListings([]);
        }
    }, [api, listingType, setMaxPrice, setMinPrice, setMaxSize, setMinSize, setTags, setPagination, queryParameters]);

    useEffect(() => {
        // Load the initial page when the component mounts
        loadListings(0, pageNumber);
    }, [loadListings, pageNumber]);

    return { listings, loadListings, listingsCount, offset, total };
}
