import { useEffect } from "react";

import lightGallery from "lightgallery";

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

export default function ImageGallery({ images, listing }) {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };

    useEffect(() => {
        // initialize lightgallery when the component mounts
        lightGallery(document.getElementById('gallery'), {
            selector: '.gallery-item',
            plugins: [lgThumbnail, lgZoom],
            speed: 500,
            onInit: onInit,
        });
    }, []);

    return (
        <SimpleBar className="container overflow-auto mb-4 pb-3">
            <div id="gallery" className="row g-2 g-md-3 gallery" data-thumbnails="true" style={{ minWidth: '30rem' }}>
                <div className="col-8">
                    {/* Set the first index of images here */}
                    {images[0] && (
                        <>
                            <a className="gallery-item rounded rounded-md-3" href={images[0]} data-src={images[0]} data-sub-html={listing.title}>
                                <img alt={listing.title} src={images[0]} />
                            </a>
                        </>
                    )}
                </div>
                {/* Set the next two indices here */}
                <div className="col-4">
                    {images[1] && (
                        <a className="gallery-item rounded rounded-md-3 mb-2 mb-md-3" href={images[1]} data-src={images[1]} data-sub-html={listing.title}>
                            <img
                                src={images[1]} alt={listing.title} />
                        </a>
                    )}

                    {images[2] && (
                        <a className="gallery-item rounded rounded-md-3" href={images[2]} data-src={images[2]} data-sub-html={listing.title}>
                            <img src={images[2]} alt={listing.title} />
                        </a>
                    )}
                </div>
                {/* Add the next 5 images here, and set the remaining images count in the span element with class fs-base */}
                <div className="col-12">
                    <div className="row g-2 g-md-3">
                        {images.slice(3, 8).map((image, index) => (
                            <div className="col" key={index}>
                                {image && (
                                    <a className="gallery-item rounded-1 rounded-md-2" href={image} data-src={image} data-sub-html={listing.title}>
                                        <img src={image} alt={listing.title} />
                                    </a>
                                )}
                            </div>
                        ))}
                        {images[8] && (
                            <div className="col">
                                <a className="gallery-item more-item rounded-1 rounded-md-2" href={images[8]} data-src={images[8]} data-sub-html={listing.title}>
                                    <img src={images[8]} alt={listing.title} />
                                    <span className="gallery-item-caption fs-base">+{images.length - 9}
                                        <span className='d-none d-md-inline'>photos</span>
                                    </span>
                                </a>
                            </div>
                        )}
                        {/* Add the remaining images to the gallery without displaying them on the page */}
                        {images.slice(9).map((image, index) => (
                            <div key={index}>
                                {image && (
                                    <a
                                        className="gallery-item rounded-1 rounded-md-2"
                                        href={image}
                                        data-src={image}
                                        data-sub-html={listing.title}
                                        style={{ display: 'none' }} // Hide the images on the page
                                    >
                                        <img src={image} alt={listing.title} />
                                    </a>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </SimpleBar>
    );
}
