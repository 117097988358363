// AllListings.js
import { useMetaData } from "../Contexts/MetadataContext";
import Listings from "../Listings";
import BreadCrumbs from "../Partials/BreadCrumbs";
import Pagination from "../Partials/Pagination";
import SortBy from "../Partials/SortBy";
import { useListings } from "../Hooks/useListings";

export default function AllListings({ queryParameters }) {
    const { pagination } = useMetaData();
    const listingType = "All listings";

    // Use the custom hook to fetch listings data
    const { listings, loadListings, listingsCount, offset, total } = useListings(listingType, queryParameters);

    return (
        <div className="col-lg-8 col-xl-9 position-relative overflow-hidden pb-5 pt-4 px-3 px-xl-4 px-xxl-5">
            <BreadCrumbs />
            <SortBy offset={offset} listingsCount={listingsCount} count={listingsCount} total={total} />
            <div className="row g-4 py-4">
                {/* Pass the listings data to the Listings component */}
                <Listings listings={listings} />
            </div>
            <Pagination pagination={pagination} loadNext={loadListings} />
        </div>
    );
}
