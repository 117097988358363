// import Locations from "./Locations";
import ContactUsForm from "./ContactUsForm";
import Filters from "./Filters";

export default function Aside({ classList, page, setQueryParameters, queryParameters }) {

    return (
        <aside className={classList}>
            {page === "Properties" ?
                (
                    <Filters
                        queryParameters={queryParameters}
                        setQueryParameters={setQueryParameters}
                    />
                ) : (
                    <ContactUsForm />
                )}
        </aside >
    );
}