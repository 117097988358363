import Container from "./Container";
import Navbar from "./Navbar";

export default function Header() {
    const containerClasses = ['container']

    return (
        <header className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
            <Container containerClasses={containerClasses}>
                <Navbar />
            </Container>
        </header>
    );
};