import { useRef, useState } from "react";
import InputField from "../Elements/InputField";
import Label from "../Elements/Label";
import Textarea from "../Elements/Textarea";
import Form from "../Form";
import { useApi } from "../Contexts/ApiProvider";

export default function ContactUsForm() {

    const leadNameField = useRef();
    const leadEmailField = useRef();
    const leadPhoneNumberField = useRef();
    const viewingDateField = useRef();
    const messageField = useRef();
    const receiveEmailsField = useRef();

    const api = useApi();

    const [formErrors, setFormErrors] = useState({});

    // validate email format
    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    }

    const onSubmit = async (ev) => {
        ev.preventDefault();

        const leadName = leadNameField.current.value;
        const leadEmail = leadEmailField.current.value;

        const errors = {};

        if (!leadName) {
            errors.leadName = "Kindly let us know your name";
        }

        if (!leadEmail) {
            errors.leadEmail = "Kindly let us know your email";
        } else if (!validateEmail(leadEmail)) {
            errors.leadEmail = "Invalid email format";
        }

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

            const propertyUrl = window.location.href;
            const message = messageField.current.value;
            const name = leadNameField.current.value;
            const email = leadEmailField.current.value;
            const phone = leadPhoneNumberField.current.value;
            const viewing_date = viewingDateField.current.value;
            const receive_emails = receiveEmailsField.current.checked;

            let contactDetails = "\n\n*My contact details:* ";

            if (phone) {
                contactDetails += "\n*Phone:* " + phone
            }

            contactDetails += "\n*Email:* " + email;

            const messageTxt = message ? "\n\n*Message:* " + message : ''

            const viewingDateTxt = viewing_date ? "\n\n*Preferred viewing date:* " + viewing_date : '';

            const text = encodeURIComponent(`Hi, my name is ${name}\n\nI am interested in the following property listed on your site: ${propertyUrl}.${messageTxt}${contactDetails}.${viewingDateTxt}`);

            let prefilledLink;
            if (isMobile) {
                prefilledLink = `https://wa.me/254733650737?text=${text}`;
            } else {
                prefilledLink = `https://web.whatsapp.com/send?phone=254733650737&text=${text}`;
            }

            const data = await api.post("/leads", {
                name: name,
                email: email,
                phone: phone,
                message: message,
                viewing_date: viewing_date ? viewing_date : null,
                receive_emails: receive_emails
            });

            if (!data.ok) {
                setFormErrors(data.body.errors.json);
            } else {
                setFormErrors({});

                window.open(prefilledLink, '_blank');
            }
        }
    }

    return (
        <div className="card shadow mb-4">
            <div className="card-body">
                <div className="d-flex align-items-start justify-content-between">
                    <a
                        className="text-decoration-none" href="real-estate-vendor-properties.html">
                        <h5 className="mb-3">Contact Us</h5>
                    </a>
                </div>
                <Form onSubmit={onSubmit}>
                    <div className="mb-3">
                        <InputField classList="form-control" type="text" placeholder="Your name*" name="lead_name" required={true} inputRef={leadNameField} error={formErrors.leadName} />
                    </div>
                    <div className="mb-3">
                        <InputField classList="form-control" type="email" placeholder="Your email*" name="lead_email" required={true} inputRef={leadEmailField} error={formErrors.leadEmail} />
                    </div>
                    <div className="mb-3">
                        <InputField classList="form-control" type="email" placeholder="Your phone number" name="lead_phone_number" inputRef={leadPhoneNumberField} error={formErrors.leadPhoneNumber} />
                    </div>
                    <div className="mb-3">
                        <InputField classList="form-control" type="date" placeholder="Choose a date for viewing" name="viewing_date" inputRef={viewingDateField} />
                    </div>
                    <div className="mb-3">
                        <Textarea classList="form-control" textRef={messageField} />
                    </div>
                    <div className="form-check mb-4">
                        <InputField classList="form-check-input" id="form-submit" type="checkbox" label="Send new listings and property tips to my email." inputRef={receiveEmailsField} />
                        <Label classList="form-check-label fs-sm" _for="form-submit" value="Send new listings and property tips to my email." />
                    </div>
                    <button className="btn btn-lg btn-primary d-block w-100" type="submit">Send request</button>
                </Form>

            </div>
        </div>
    );
}