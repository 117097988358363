import Container from "../components/Container";
import PageWrapper from "../components/PageWrapper";
import Aside from "../components/Partials/Aside";
import AllListings from "../components/Sections/AllListings";

export default function PropertiesPage({ setQueryParameters, queryParameters }) {
    const containerClasses = 'container-fluid mt-5 pt-5 p-0'
    const asideClassList = ["col-lg-4 col-xl-3 border-top-lg border-end-lg shadow px-3 px-xl-4 px-xxl-5 pt-lg-2"]
    const _page = "Properties"

    return (
        <PageWrapper page={_page}>
            <Container containerClasses={containerClasses}>
                <div className="row g-0 mt-n3">
                    <Aside
                        classList={asideClassList}
                        page={_page}
                        setQueryParameters={setQueryParameters}
                        queryParameters={queryParameters}
                    />
                    <AllListings
                        queryParameters={queryParameters}
                    />
                </div>
            </Container>
        </PageWrapper>
    )
}