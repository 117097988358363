import { useState } from 'react';

export default function ImageSlider({ images }) {
    const [activeIndex, setActiveIndex] = useState(0);

    const handlePaginationClick = (index) => {
        setActiveIndex(index);
    };

    const handlePrevClick = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const handleNextClick = () => {
        if (activeIndex < images.length - 1) {
            setActiveIndex(activeIndex + 1);
        }
    };

    return (
        <div className="tns-carousel-wrapper card-img-top card-img-hover">
            {/* Your other carousel content */}
            <div className="tns-controls" aria-label="Carousel Navigation" tabIndex="0">
                <button
                    type="button"
                    data-controls="prev"
                    tabIndex="-1"
                    aria-controls="tns1"
                    onClick={() => {
                        handlePrevClick();
                    }}
                >
                    <i className="fi-chevron-left"></i>
                </button>
                <button
                    type="button"
                    data-controls="next"
                    tabIndex="-1"
                    aria-controls="tns1"
                    onClick={() => {
                        handleNextClick();
                    }}
                >
                    <i className="fi-chevron-right"></i>
                </button>
            </div>
            <div id="tns1-mw" className="tns-ovh">
                <div className="tns-inner" id="tns1-iw">
                    <div className="tns-carousel-inner  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal" id="tns1">
                        {images.map((image, index) => (
                            <img
                                src={image}
                                alt={`${index + 1}`}
                                className={`custom-tns-item ${index === activeIndex ? 'active' : ''}`}
                                id={`tns1-item${index}`}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="tns-nav custom-tns-nav" aria-label="Carousel Pagination">
                {images.map((_, index) => (
                    <button
                        type="button"
                        data-nav={index}
                        aria-controls="tns1"
                        aria-label={`Carousel Page ${index + 1}`}
                        className={index === activeIndex ? 'tns-nav-active' : ''}
                        onClick={() => {
                            handlePaginationClick(index);
                        }}
                        key={index}
                    ></button>
                ))}
            </div>
        </div>
    );
}
