export default function Footer() {
    return (
        <footer className="footer bg-secondary pt-5">
            <div className="container pt-lg-4 pb-4">
                <div className="row mb-5 pb-md-3 pb-lg-4">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                        <div className="d-flex flex-sm-row flex-column justify-content-between mx-n2">
                            <div className="mb-sm-0 mb-4 px-2">
                                <a className="d-inline-block mb-4" href="real-estate-home-v1.html">
                                    <img src="/kajiado_acres.svg" width="116" alt="kajiado acres" />
                                </a>
                                <ul className="nav flex-column mb-sm-4 mb-2">
                                    <li className="nav-item mb-2">
                                        <a className="nav-link p-0 fw-normal" href="mailto:info@kajiadoacres.com">
                                            <i className="fi-mail mt-n1 me-2 align-middle opacity-70"></i>
                                            info@kajiadoacres.com
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link p-0 fw-normal" href="tel:+254733650737">
                                            <i className="fi-device-mobile mt-n1 me-2 align-middle opacity-70"></i>
                                            +254733650737
                                        </a>
                                    </li>
                                </ul>
                                <div className="pt-2"><a
                                    className="btn btn-icon btn-light-primary btn-xs shadow rounded-circle me-2 mb-2"
                                    href="?"><i className="fi-facebook"></i></a><a
                                        className="btn btn-icon btn-light-primary btn-xs shadow rounded-circle me-2 mb-2"
                                        href="?"><i className="fi-twitter"></i></a><a
                                            className="btn btn-icon btn-light-primary btn-xs shadow rounded-circle me-2 mb-2"
                                            href="?"><i className="fi-viber"></i></a><a
                                                className="btn btn-icon btn-light-primary btn-xs shadow rounded-circle me-2 mb-2"
                                                href="?"><i className="fi-telegram"></i></a></div>
                            </div>
                            <div className="mb-sm-0 mb-4 px-2">
                                <h4 className="h5">Property Type</h4>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">Farm land</a>
                                    </li>
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">Mixed use land</a>
                                    </li>
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">Commercial land</a>
                                    </li>
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">Residential
                                        land</a></li>
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">Rocky/Quarry
                                        land</a></li>
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">Houses &
                                        Apartments</a></li>
                                </ul>
                            </div>
                            <div className="px-2">
                                <h4 className="h5">Quick Links</h4>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">List a property</a>
                                    </li>
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">Our services</a>
                                    </li>
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">About us</a></li>
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">Contacts</a></li>
                                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="?">News & Articles</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center fs-sm pt-4 mt-3 pb-2">
                    &copy; Kajiado Acres
                </div>
            </div>
        </footer>
    );
};