import { createContext, useContext, useState } from "react";

// Create a context object
const MetadataContext = createContext();

// Create a provider component to wrap your application
export function MetadataProvider({ children }) {
    const [maxPrice, setMaxPrice] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [maxSize, setMaxSize] = useState(0);
    const [minSize, setMinSize] = useState(0);
    const [tags, setTags] = useState([]);
    const [pagination, setPagination] = useState(null)

    return (
        <MetadataContext.Provider value={{ maxPrice, minPrice, maxSize, minSize, tags, pagination, setMaxPrice, setMinPrice, setMinSize, setMaxSize, setTags, setPagination }}>
            {children}
        </MetadataContext.Provider>
    );
}

// Create a custom hook to access the context
export function useMetaData() {
    return useContext(MetadataContext);
}
