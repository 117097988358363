import Footer from "./Footer";
import Header from "./Header";
import SidebarButton from "./Partials/SidebarButton";
import Section from "./Sections/Section";

export default function PageWrapper({ children, page }) {
    return (
        <>
            <main className="page-wrapper">
                <Header />
                {page === "Index" ? (
                    <Section>
                        {children}
                    </Section>
                ) : (
                    children
                )}

            </main>
            <Footer />
            {page === "Properties" ? (
                <SidebarButton />
            ) : null}
        </>
    );
};