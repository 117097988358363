import { useEffect, useState } from "react";
import { useApi } from "../Contexts/ApiProvider";
import { useParams } from "react-router-dom";

export default function useListing() {
    const { id } = useParams();
    const [listing, setListing] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const api = useApi();

    useEffect(() => {
        (async () => {
            try {
                const response = await api.get('/listings/' + id);
                if (response.ok) {
                    setListing(response.body);
                } else {
                    setError(response.error);
                }
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [id, api])

    return { listing, isLoading, error };
}
